//
// Variation Controller
//

import { onClick, onLoaded, getElementInteger, onChange, getElementString } from '../utilities'

export default class VariationController {
  public static readonly shared = new VariationController()

  /// Returns the next available index.
  private nextAvailableIndex = 1

  // MARK: - Indexes

  private nextIndex() {
    return this.nextAvailableIndex++
  }

  // MARK: - Object Lifecycle

  private constructor() {
    onLoaded(this.buildSelectButtonsElements.bind(this))
    onClick('.select-buttons .select-buttons__button', this.updateSelectedValue.bind(this))
    onChange('select[data-select-buttons-id]', this.updateSelectButtons.bind(this))
  }

  // MARK: - Setup

  private buildSelectButtonsElements() {
    document.querySelectorAll('form.variations_form table.variations select').forEach((selectElement) => {
      if (selectElement instanceof HTMLSelectElement === false) return

      const tableElement = selectElement.closest('table.variations')
      if (tableElement) tableElement.classList.add('variations--select-buttons')

      this.buildSelectButtonsElement(selectElement)
    })
  }

  private buildSelectButtonsElement(selectElement: HTMLSelectElement) {
    const selectButtonsIndex = this.nextIndex()
    const selectButtonsElement = document.createElement('div')
    selectButtonsElement.classList.add('select-buttons')
    selectButtonsElement.setAttribute('data-select-buttons-id', selectButtonsIndex.toString())

    const optionsElements = selectElement.querySelectorAll('option')
    optionsElements.forEach((optionElement) => {
      if (optionElement instanceof HTMLOptionElement === false) return
      if (optionElement.value === '') return

      const buttonElement = document.createElement('button')
      buttonElement.type = 'button'
      buttonElement.classList.add('select-buttons__button')
      buttonElement.setAttribute('data-select-value', optionElement.value)
      buttonElement.innerHTML = optionElement.innerHTML
      selectButtonsElement.appendChild(buttonElement)
    })

    selectElement.insertAdjacentElement('afterend', selectButtonsElement)
    selectElement.setAttribute('data-select-buttons-id', selectButtonsIndex.toString())
  }

  // MARK: - Actions

  private updateSelectedValue(element: Element) {
    const selectButtonsElement = element.closest('.select-buttons')
    if (selectButtonsElement instanceof HTMLElement === false) return

    const selectButtonsIndex = getElementInteger(selectButtonsElement, 'data-select-buttons-id')
    if (selectButtonsIndex == null) return

    const selectElement = document.querySelector(`select[data-select-buttons-id="${selectButtonsIndex}"]`)
    if (selectElement instanceof HTMLSelectElement === false) return

    selectElement.value = element.getAttribute('data-select-value') || ''

    const changeEvent = new Event('change', { bubbles: true })
    selectElement.dispatchEvent(changeEvent)
  }

  private updateSelectButtons(element: Element) {
    if (element instanceof HTMLSelectElement === false) return

    const selectButtonsIndex = getElementInteger(element, 'data-select-buttons-id')
    if (selectButtonsIndex == null) return

    const selector = `.select-buttons[data-select-buttons-id="${selectButtonsIndex}"]`
    const selectButtonsElements = document.querySelectorAll(selector)
    selectButtonsElements.forEach((selectButtonsElement) => {
      const selectButtonElements = selectButtonsElement.querySelectorAll('.select-buttons__button')
      selectButtonElements.forEach((selectButtonElement) => {
        const value = getElementString(selectButtonElement, 'data-select-value') || ''

        if (value === element.value) {
          selectButtonElement.classList.add('select-buttons__button--selected')
        } else {
          selectButtonElement.classList.remove('select-buttons__button--selected')
        }
      })
    })
  }
}
