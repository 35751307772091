//
// Routine Controller
//

import { onChange, onLoaded, getElementFloat, getElementString } from '../utilities'

export default class RoutineController {
  public static readonly shared = new RoutineController()

  private priceFormatters: Map<string, Intl.NumberFormat>

  // MARK: - Object Lifecycle

  private constructor() {
    this.priceFormatters = new Map()

    onLoaded(this.updateRoutinePrices.bind(this))
    onChange(
      '.routine .routine__product .routine__product__optional input[type="checkbox"]',
      this.updateRoutinePrice.bind(this)
    )
  }

  // MARK: - UI

  public updateRoutinePrices() {
    document.querySelectorAll('.routine').forEach((routineElement) => {
      this.updateRoutinePrice(routineElement)
    })
  }

  public updateRoutinePrice(referenceElement: Element) {
    const routineElement = referenceElement.closest('.routine')
    if (routineElement instanceof Element === false) return

    this.updateSubmitElements(routineElement)
    this.updateDiscountBadgeElements(routineElement)
  }

  public updateSubmitElements(routineElement: Element) {
    const submitElement = routineElement.querySelector('.routine__form button[type="submit"]')
    if (submitElement instanceof HTMLButtonElement === false) return

    let priceElement = submitElement.querySelector('.routine__form__price')
    if (priceElement === null) {
      const separatorElement = document.createElement('span')
      separatorElement.innerHTML = '&nbsp;&ndash;&nbsp;'
      submitElement.appendChild(separatorElement)

      priceElement = document.createElement('span')
      priceElement.classList.add('routine__form__price')
      submitElement.appendChild(priceElement)
    }

    const price = this.calculatePrice(routineElement, 'price')
    const formattedPrice = this.formatPrice(routineElement, price)
    priceElement.textContent = formattedPrice || '?'
  }

  public updateDiscountBadgeElements(routineElement: Element) {
    const regularPrice = this.calculatePrice(routineElement, 'regular-price')
    const salePrice = this.calculatePrice(routineElement, 'price')
    const discountPrice = regularPrice - salePrice
    const formattedSalePrice = this.formatPrice(routineElement, discountPrice)

    routineElement.querySelectorAll('.routine__discount-badge').forEach((priceElement) => {
      priceElement.classList.toggle('routine__discount-badge--show', discountPrice > 0)
    })

    routineElement.querySelectorAll('.routine__discount-badge__price').forEach((priceElement) => {
      priceElement.textContent = formattedSalePrice || '?'
    })
  }

  // MARK: - Calculations

  public calculatePrice(routineElement: Element, type: string) {
    let bundlePrice = getElementFloat(routineElement, `data-bundle-${type}`) || 0.0

    routineElement.querySelectorAll('.routine__product').forEach((routineProductElement) => {
      const checkboxElement = routineProductElement.querySelector('.routine__product__optional input[type="checkbox"]')
      if (checkboxElement instanceof HTMLInputElement && checkboxElement.checked === false) return

      bundlePrice += getElementFloat(routineProductElement, `data-bundle-item-${type}`) || 0.0
    })

    return bundlePrice
  }

  // MARK: - Price Formatting

  private getPriceFormatter(currency: string): Intl.NumberFormat {
    let priceFormatter = this.priceFormatters.get(currency)
    if (priceFormatter == null) {
      priceFormatter = new Intl.NumberFormat(navigator.language, { style: 'currency', currency })
      this.priceFormatters.set(currency, priceFormatter)
    }

    return priceFormatter
  }

  private formatPrice(routineElement: Element, price: number): string | undefined {
    const currency = getElementString(routineElement, 'data-bundle-currency')
    if (currency == null) return undefined

    const priceFormatter = this.getPriceFormatter(currency)
    return priceFormatter.format(price).replace(/^[A-Z]{2}\$/, '$')
  }
}
